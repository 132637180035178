import * as React from "react";
import { graphql } from "gatsby";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import {
  SectionContainer,
  SectionWrapper
} from "../components/Section";

import { LegalHero } from "../components/Hero";
import { MarkdownRenderer } from "../components/Markdown";


interface Props {
  data: any
}


const LegalTemplate: React.FC<Props> = ( props ) => {
  const {
    mdx: {
      body,
      frontmatter: {
        seo_title,
        seo_description,
        heading,
        last_updated
      }
    },
  } = props.data;

  return (
    <Layout>
      <Head
        title={ seo_title }
        description={ seo_description }
      />

      <SectionWrapper
        padding={{
          base: '38px 0 32px',
          lg: '60px 0 48px'
        }}
      >
        <SectionContainer>
          <LegalHero
            heading={ heading }
            lastUpdated={ last_updated }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper>
        <SectionContainer>
          <MarkdownRenderer
            body={ body }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query Legal($id: String) {
    mdx(
      id: {
        eq: $id
      }
    ) {
      body
      frontmatter {
        seo_title
        seo_description
        heading
        last_updated(
          formatString: "MMMM Do, YYYY"
        )
      }
    }
  }
`;


export default LegalTemplate;
